import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const SubtopicNavigation = ({ courseId, currentTopic, currentSubtopic }: { courseId: string, currentTopic: any, currentSubtopic: any }) => {

    const subtopics = currentTopic.subtopics;
    const currentSubtopicIndex = subtopics.findIndex((st: { id: any; }) => st.id === currentSubtopic.id);
    const allCompleted = subtopics.every((subtopic: any) => subtopic.isCompleted);


    // Визначення попереднього та наступного сабтопіків
    const prevSubtopic = subtopics[currentSubtopicIndex - 1];
    const nextSubtopic = subtopics[currentSubtopicIndex + 1];

    return (
        <div className='flex flex-col md:flex-row gap-3 lg:gap-0 text-center w-full justify-between py-6'>
            {/* Кнопка для повернення до курсу */}
            {currentSubtopicIndex !== subtopics.length - 1 && <Link className='bg-yellow-500 text-white py-2 px-4 rounded-3xl hover:bg-yellow-600' to={`/courses/${courseId}`}>  <button>Повернутись до курсу</button>  </Link>}


            {/* Кнопка для попереднього сабтопіка */}
            {prevSubtopic && (
                <Link className='bg-yellow-400 text-white py-2 px-4 rounded-3xl hover:bg-yellow-600 cursor-pointer' to={`/courses/${courseId}/${prevSubtopic.id}`}>
                    <button>Попередній урок</button>
                </Link>
            )}

            {/* Кнопка для наступного сабтопіка */}
            {currentSubtopicIndex !== subtopics.length - 1 && ((nextSubtopic) ? (
                <Link className='bg-green-500 text-white py-2 px-4 rounded-3xl hover:bg-green-600' to={`/courses/${courseId}/${nextSubtopic.id}`}>

                    Наступний урок

                </Link>
            ) : <>

                <span data-tooltip-id={`denied`} className='bg-red-500 text-white py-2 px-4 rounded-3xl hover:bg-red-600 cursor-pointer'>

                    Наступний урок

                </span>

                <ReactTooltip id={`denied`} place='top'>
                    Потрібно пройти завдання
                </ReactTooltip>
            </>
            )}

            {/* Якщо це останній сабтопік */}
            {currentSubtopicIndex === subtopics.length - 1 &&
                        <Link className='bg-green-400 text-white py-2 px-4 rounded-3xl hover:bg-yellow-5000' to={`/courses/${courseId}`}>
                            До курсу
                        </Link>         
}
        </div>
    );
};

export default SubtopicNavigation;
