import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import Button from "../Button";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addSubTopic } from "../../actions/courseActions";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { UserRole } from "../../types/User";

interface Subtopic {
    progress: number;
    isCompleted: boolean;
}

interface Topic {
    isCompleted: boolean;
    progress: number;
    subtopics: Record<string, Subtopic>;
}

interface Course {
    topics: Record<string, Topic>;
    courseProgress: number;
}


interface User {
    userId: string;
    email: string;
    emailVerified: boolean;
    metadata: {
        createdAt: string;
        lastLoginAt: string;
    };
    role: string;
    courses: any;
}

function TopicCard({ name, id, courseId, index, userSubtopics }: { name: string, id: string, courseId: string, index: number, userSubtopics: any }) {

    const [subTopicName, setSubTopicName] = useState('')
    const [image, setImage] = useState<File | null>(null);
    const [completedSubtopics, setCompletedSubtopics] = useState(0);
    const [totalSubtopics, setTotalSubtopics] = useState(3);

    const user = useSelector((state: { auth: { user: User } }) => state.auth.user);

    const dispatch = useDispatch();

    const handleImageUpload = async (file: File) => {
        const storage = getStorage();
        const storageRef = ref(storage, `course-images/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
    };

    const subtopics = useSelector((state: any) =>
        state.course.selectedCourse?.topics.find((topic: any) => topic.id === id)?.subtopics
    );

    useEffect(() => {
        if (user) {
            // Check if user.courses is defined and if courseId is a valid key
            const courses = user.courses;
            if (courses && courses[courseId]) {
                const topics = courses[courseId].topics;

                if (topics) {
                    // Find the subtopic
                    const subtopics = topics.find((i: any) => id === i.id);

                    if (subtopics) {
                        const completedCount = subtopics.subtopics.filter((subtopic: { isCompleted: boolean; }) => subtopic.isCompleted).length;
                        const totalCount = subtopics.subtopics.length;
                        setCompletedSubtopics(completedCount);
                        setTotalSubtopics(totalCount);
                    } else {
                        // Handle case where subtopics are not found
                        console.warn(`Subtopic with id ${id} not found.`);
                    }
                } else {
                    console.warn(`Topics not found for courseId ${courseId}.`);
                }
            } else {
                console.warn(`Courses or courseId ${courseId} is invalid.`);
            }
        }
    }, [user, courseId, id]);


    async function addSubTopicHandler() {
        if (name && image) {
            try {
                const imgUrl = await handleImageUpload(image);
                dispatch(addSubTopic({ courseId, id, subTopicName, imgUrl }));
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    }
    
    return (
        <div className="flex flex-col md:w-fit md:items-start items-center w-full p-6 gap-6">
            <h1
                className='text-xl lg:text-4xl pb-2 lg:pb-6 flex items-start lg:items-end gap-1'>
                Temat {index + 1} :  <span className="font-medium">{name}</span> <span className="text-lg">({completedSubtopics} з {totalSubtopics})</span> {completedSubtopics === totalSubtopics && <span><svg xmlns="http://www.w3.org/2000/svg" height='40px' width="40px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64"><path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
	l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" /></svg> </span>}
            </h1>
            <div>
                <div className='flex flex-col '>
                    
                    {/* {user && user.role === UserRole.Admin &&
                        <>
                            <input
                                type="text"
                                value={subTopicName}
                                onChange={(e) => setSubTopicName(e.target.value)}
                                placeholder="Назва Теми"
                                className='border'
                            />
                            <input
                                type="file"
                                onChange={(e) => setImage(e.target.files ? e.target.files[0] : null)}
                            />
                            <Button variant='secondary' text='Додати підТему' onClick={addSubTopicHandler} />
                        </>
                    } */}



                    {userSubtopics && userSubtopics.length > 0 ? (
                        <div className="flex gap-10 flex-col items-center md:flex-row">
                            {userSubtopics.map((subtopic: { id: string; name: string | undefined; imgUrl: string, isLocked: boolean, isCompleted: boolean }, index: number) => (
                                <div className="flex" key={subtopic.id}>
                                     
                                        <div className="relative w-full md:w-[220px] flex flex-col items-center justify-center rounded-2xl backdrop-blur-md bg-white/75 duration-300 transform p-3 hover:scale-105 hover:bg-white transition-all gap-3" key={subtopic.id}>
                                            <Link className=" w-full flex flex-col items-center" to={subtopic.id}>
                                                <div className="w-full">
                                                    <h1 className="text-start text-lg font-medium cursor-pointer">{index + 1}. {subtopic.name}</h1>
                                                </div>
                                                <div className="overflow-hidden h-32 w-40 rounded-xl">
                                                    <img className="object-cover h-32 w-40" src={subtopic.imgUrl} alt="" />
                                                </div>
                                                {subtopic.isCompleted && <svg className="w-6 h-6 absolute top-2 right-2" xmlns="http://www.w3.org/2000/svg" height='40px' width="40px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64"><path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
	l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" /></svg>}
                                            </Link>
                                        </div>
                                    
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>Немає підтем</p>
                    )}

                </div>
            </div>

        </div >
    );
};


export default TopicCard