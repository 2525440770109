import React, { useEffect, useState } from 'react';
import { updateSubtopicTask } from '../../utils/updateSubtopicTask';
import styles from './ImageMatchTask.module.scss'; // Підключення SCSS модуля
import { useDispatch } from 'react-redux';

interface Image {
  url: string;
  word: string;
}

interface ImageMatchQuestion {
  images: Image[];
  options: string[];
}

interface ImageMatchTaskProps {
  user: any;
  courseId: string;
  topicId: string;
  subtopicId: string;
  taskId: string;
  questions: ImageMatchQuestion;
  onComplete: () => void;
}

const ImageMatchTask: React.FC<ImageMatchTaskProps> = ({ user, courseId, topicId, subtopicId, taskId, questions, onComplete }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>(Array(questions.images.length).fill(''));
  const [feedback, setFeedback] = useState<boolean[]>(Array(questions.images.length).fill(false));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const dispatch = useDispatch();

  const handleAnswerChange = (imageIndex: number, selectedWord: string) => {
    const newAnswers = [...selectedAnswers];
    newAnswers[imageIndex] = selectedWord;
    setSelectedAnswers(newAnswers);
  };

  const handleSubmit = async () => {
    const feedbackArray = questions.images.map((image, imageIndex) => {
      return selectedAnswers[imageIndex] === image.word;
    });
    setFeedback(feedbackArray);
    setIsSubmitted(true);

    // Збереження відповідей у Firebase
    try {
      await updateSubtopicTask(
        user,
        courseId,
        topicId,
        subtopicId,
        taskId,
        true,
        selectedAnswers,
        dispatch
      );
      console.log('Task results saved successfully!');
    } catch (error) {
      console.error('Error saving task results:', error);
    }
  };

  useEffect(() => {
    const fetchUserAnswers = async () => {
      try {
        if (user) {
          const courseUserData = user.courses?.[courseId];
          if (courseUserData) {
            const topicData = courseUserData.topics.find((topic: any) => topic.id === topicId);
            const subtopicData = topicData?.subtopics?.find((subtopic: any) => subtopic.id === subtopicId);
            const taskData = subtopicData?.tasks?.find((task: any) => task.id === taskId);

            if (taskData && taskData.userInputs) {
              setSelectedAnswers(taskData.userInputs);

              // Обчислення feedback на основі завантажених відповідей
              const initialFeedback = questions.images.map((image, imageIndex) => {
                return taskData.userInputs[imageIndex] === image.word;
              });
              setFeedback(initialFeedback);
              setIsSubmitted(true); // Встановлюємо як "відправлено", щоб класи вже відображалися\
              setIsTaskCompleted(taskData.isCompleted)
            }
            setIsFetched(true)
          }
        }
      } catch (error) {
        console.error('Error fetching user answers:', error);
      }
    };

    fetchUserAnswers();
  }, [user, topicId, subtopicId, taskId, courseId]);


  useEffect(() => {
    const updateTask = async () => {
      if (isFetched) {
        updateSubtopicTask(user, courseId, topicId, subtopicId, taskId, isTaskCompleted ?? false, selectedAnswers, dispatch).then(() => { onComplete() });;
      }
    };
    updateTask();
  }, [isFetched]);

  return (
    <>
      <div className='flex flex-wrap justify-center gap-6 mb-6'>
        {questions.images.map((image, imageIndex) => (
          <label
            htmlFor={imageIndex.toString()}
            className={`flex flex-col ${styles.imageContainer}`}
            key={imageIndex}
          >
            <div className='w-[150px] h-[150px] bg-white flex'>
              <img
                className={`rounded-t-2xl ${styles.image} object-contain`}
                src={image.url}
                alt="Task"
              />
            </div>
            <select
              id={imageIndex.toString()}
              className={`w-[150px] rounded-b-2xl p-2 border-none hover:bg-slate-50 cursor-pointer ${isSubmitted
                ? feedback[imageIndex]
                  ? styles.correct
                  : styles.incorrect
                : ''
                }`}
              value={selectedAnswers[imageIndex]}
              onChange={(e) => handleAnswerChange(imageIndex, e.target.value)}
            >
              <option value="" disabled>Вибери правильну назву</option>
              {(() => {
                const correctAnswer = image.word; // Правильна відповідь
                const incorrectOptions = questions.options.filter(opt => opt !== correctAnswer); // Видаляємо правильну відповідь
                const randomOptions = incorrectOptions
                  .sort(() => Math.random() - 0.5) // Перемішуємо неправильні відповіді
                  .slice(0, 4); // Беремо лише 4 (разом з правильною буде 5)

                const finalOptions = [...randomOptions, correctAnswer] // Додаємо правильну відповідь
                  .sort(() => Math.random() - 0.5); // Перемішуємо фінальний список

                return finalOptions.map((option, optionIndex) => (
                  <option key={optionIndex} value={option}>
                    {option}
                  </option>
                ));
              })()}
            </select>
          </label>
        ))}
      </div>
      <button className='p-3 text-base rounded-2xl bg-green-400/60 hover:bg-green-400' onClick={handleSubmit}>
        Перевірити відповіді
      </button>
    </>
  );
};

export default ImageMatchTask;
