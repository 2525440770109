import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './ListenTask.module.scss';
import { updateSubtopicTask } from '../../utils/updateSubtopicTask';

const ListenTask = ({ words, courseId, user, topicId, subtopicId, taskId, onComplete }: { words: any, courseId: string, user: any, topicId: string, subtopicId: string, taskId: string, onComplete: () => void; }) => {
  const [responses, setResponses] = useState(Array(words.length).fill(''));
  const [results, setResults] = useState(Array(words.length).fill(null));
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const dispatch = useDispatch();

  // Програвання тексту або аудіо залежно від наявності `audioUrl`
  const handlePlayAudio = (word: string, audioUrl: string | null) => {
    if (audioUrl) {
      // Відтворення аудіофайлу, якщо доступний `audioUrl`
      const audio = new Audio(audioUrl);
      audio.play();
    } else {
      window.speechSynthesis.cancel();

      const utterance = new SpeechSynthesisUtterance(word);
      utterance.lang = 'pl-PL';

      // Запуск відтворення після завантаження голосів
      const voices = window.speechSynthesis.getVoices();
      if (voices.length) {
        window.speechSynthesis.speak(utterance);
      } else {
        window.speechSynthesis.onvoiceschanged = () => {
          window.speechSynthesis.speak(utterance);
        };
      }
    }
  };

  useEffect(() => {
    // Завантаження даних користувача
    const loadUserResponses = async () => {
      if (user) {
        const courseUserData = user.courses?.[courseId];
        if (courseUserData) {
          const topicData = courseUserData.topics?.find((topic: any) => topic.id === topicId);
          if (topicData) {
            const subtopicData = topicData.subtopics?.find((subtopic: any) => subtopic.id === subtopicId);
            if (subtopicData) {
              const taskData = subtopicData.tasks?.find((task: any) => task.id === taskId);
              if (taskData) {
                setResponses(taskData.userInputs || Array(words.length).fill(''));
                setResults(taskData.userInputs.map((input: any, index: any) => input.toLowerCase() === words[index]?.word.toLowerCase()));
                setIsTaskCompleted(taskData.isCompleted);
              }
            }
            setIsFetched(true);
          }
        }
      }
    };
    loadUserResponses();
  }, [courseId, user, topicId, subtopicId, taskId, words]);

  useEffect(() => {
    const updateTask = async () => {
      if (isFetched) {
        updateSubtopicTask(user, courseId, topicId, subtopicId, taskId, isTaskCompleted ?? false, responses, dispatch).then(() => {
          onComplete();
        });
      }
    };
    updateTask();
  }, [isFetched]);

  const handleInputChange = (index: number, value: any) => {
    const newResponses = [...responses];
    newResponses[index] = value;
    setResponses(newResponses);
  };

  const checkAnswers = async () => {
    const newResults = responses.map((response, index) => response.toLowerCase() === words[index].word);
    await updateSubtopicTask(user, courseId, topicId, subtopicId, taskId, true, responses, dispatch);
    setResults(newResults);
    setIsTaskCompleted(true);
  };

  return (
    <>
      <div className="flex flex-wrap gap-6 justify-center lg:justify-start mb-6">
        {words.map((i: any, index: number) => {
          const inputClass = results[index] !== null && isTaskCompleted ? (results[index] ? styles.correct : styles.incorrect) : '';

          return (
            <div className="flex flex-col items-center" key={index}>
              <button
                className="p-3 mb-3 rounded-full bg-slate-200 hover:bg-slate-400/30"
                onClick={() => handlePlayAudio(i.word, i.audioUrl)}
              >
                <svg className="h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 16V8H6L11 4V20L6 16H3Z" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M13 9C13 9 15 9.5 15 12C15 14.5 13 15 13 15" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M15 7C15 7 18 7.83333 18 12C18 16.1667 15 17 15 17" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M17 5C17 5 21 6.16667 21 12C21 17.8333 17 19 17 19" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              <input
                className={`p-2 border-none rounded-2xl outline-none ${inputClass}`}
                type="text"
                value={responses[index]}
                onChange={(e) => handleInputChange(index, e.target.value)}
                placeholder="Напиши, що почув сюди"
              />
              {!results[index] && isTaskCompleted && (
                <span className="text-[#74D474] mt-2">{i.word}</span>
              )}
            </div>
          );
        })}
      </div>
      <button className="p-3 text-base rounded-2xl bg-green-400/60 hover:bg-green-400" onClick={checkAnswers}>Перевірити відповіді</button>
    </>
  );
};

export default ListenTask;
