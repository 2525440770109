export enum UserRole {
    Admin = 'admin',
    FreeUser = 'freeUser',
    SubUser = 'subUser',
    SubUser2 = 'subUser2',
    SubUserAll ='SubUserAll'
}

interface User {
    userId: string,
    email: string | null,
    emailVerified: boolean,
    metadata: {
        createdAt?: string,
        lastLoginAt?: string
    },
    role: UserRole,
    courses: any,
    subscription?: any
}


export default User