import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

export const saveTranslationToFirebase = async (
  lessonId: string,
  taskId: number,
  word: string,
  translation: string
) => {
  try {
    // Отримуємо документ уроку
    const docRef = doc(firestore, 'lessons', lessonId);
    const lessonDoc = await getDoc(docRef);

    if (!lessonDoc.exists()) {
      console.error('Lesson document not found');
      return;
    }

    const lessonData = lessonDoc.data();

    // Переконуємось, що tasks існує і є масивом
    if (!Array.isArray(lessonData.tasks)) {
      console.error('Tasks array not found in lesson data');
      return;
    }

    // Оновлюємо відповідне завдання
    const updatedTasks = lessonData.tasks.map((task: any) => {
      if (task.id === taskId && Array.isArray(task.wordsToSpeak)) {
        return {
          ...task,
          wordsToSpeak: task.wordsToSpeak.map((w: any) =>
            w.word === word ? { ...w, translation } : w
          ),
        };
      }
      return task;
    });

    // Оновлюємо документ у Firestore
    await updateDoc(docRef, { tasks: updatedTasks });

    console.log(`Saved translation for ${word}: ${translation}`);
  } catch (error) {
    console.error('Error saving translation to Firebase:', error);
  }
};
